import React from 'react';

const FAQ = () => {
  const questions = [
    {
      id: 1,
      question: 'What are Metallic Ordinals OG?',
      answer: 'MTLX is a OG collection of 111 unique 1/1 Metallic Ordinals stored on the Ethereum blockchain.',
    },
    {
      id: 2,
      question: 'How can I mint an MTLX?',
      answer: 'The mint will be happend through the ethereum infrastrucutr. All ordninals will be airdroped in the BTC wallets.',
    },
    {
      id: 3,
      question: 'What is the benefit of owning an Metallic Ordinal?',
      answer: 'As an MTLX holder, you own a unique art piece on the Ethereum chain and an inscription on the Bitcoin chain. Additionally, you will receive future free drops of the next collections.',
    },
    {
      id: 4,
      question: 'When will the mint happen?',
      answer: 'The mint will go live in 03/23. More details coming soon.',
    },
  ];

  return (
    <section className="py-16 bg-brand-background">
      <div className="container mx-auto w-2/3">
        <h4 className="text-white font-silkscreen text-center text-4xl mb-8 border-b-2 pb-6">FAQ</h4>
        {questions.map((q) => (
          <div key={q.id} className="mb-6">
            <h5 className="text-white font-silkscreen text-center text-xl mb-2">{q.question}</h5>
            <p className="text-white text-md text-center font-silkscreen border-b-2 pb-6" >{q.answer}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;