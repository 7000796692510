const font = "font-silkscreen";

function Services() {
  return (
    <section className="py-16 bg-brand-benefits">
      <div className="container mx-auto w-2/3">
        <h4 className={`text-white ${font} text-4xl text-center mb-8`}>HOLDER BENEFITS</h4>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="hover:shadow-lg flex items-center justify-center">
            <div className={`bg-gray-400 p-8 rounded ${font}`}>
              <h3 className={`font-bold text-xl text-center ${font} mb-4`}>1/1 ART</h3>
              <p className="text-black text-sm font-silkscreen text-center">
                As a holder you own a great MTLX Artpiece on the ETH chain & an inscription on the BTC chain.
              </p>
            </div>
          </div>
          <div className="hover:shadow-lg flex items-center justify-center">
            <div className={`bg-gray-400 p-8 rounded ${font}`}>
              <h3 className={`font-bold text-xl text-center ${font} mb-4`}>HOLDER REWARDS</h3>
              <p className="text-black text-sm font-silkscreen text-center">
                Every Holder of this collection will recieve the same rewards as the ETH holders.
              </p>
            </div>
          </div>

          <div className="flex items-center justify-center">
  <div className={`p-8 rounded hover:shadow-md bg-gray-400 ${font}`}>
    <h3 className={`font-bold text-xl text-center ${font} mb-4`}>FUTURE FREE DROPS</h3>
    <p className="text-sm font-silkscreen text-center">
      Every holder of a BTC inscription receives a free airdrop of the next ETH collection.
    </p>
  </div>
</div>
        </div>
      </div>
    </section>
  );
}

export default Services;
