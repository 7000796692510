import React from "react";

const Mintdetails = () => {
  return (
    <div className="container mx-auto px-8 sm:px-12 lg:px-16">
      <section className="bg-brand-background rounded-lg">
        <h2 className="text-3xl sm:text-4xl text-white font-silkscreen text-center my-8 sm:my-10 lg:my-12">
          MINTDETAILS
        </h2>
        <div class="flex flex-col md:flex-row justify-center items-center">
          <div class="flex justify-center w-full px-2 md:w-1/2 md:px-6">
            <div class="text-white text-center font-silkscreen">
              <p class="text-base sm:text-lg">Supply: 111</p>
              <p class="text-base sm:text-lg">Mintdate: TBA</p>
              <p class="text-base sm:text-lg">Mintprice: TBA</p>
              <p class="text-base sm:text-lg">Max per Wallet: 1</p>
              <p class="text-base sm:text-lg">Mintphases: 3</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row">
          <div className="w-3/4  mx-auto text-white text-left font-silkscreen my-8 sm:my-10 lg:my-12">
            <p className="text-base sm:text-lg">Further details:</p>
            <p className="text-base sm:text-lg">
             To get your ordinal inscription, every holder has to fill out the form with the ETH and BTC wallet. The form will be released 
             after the mint. THe ordinal will be airdropped to every holder.  <br></br>
              More details soon...{" "}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Mintdetails;
