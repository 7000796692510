import React from 'react';
import banner from './banner.jpg';

const Banner = () => {
  return (
    <section className="bg-brand-background">
      <img src={banner} alt="banner" className="mx-auto w-full sm:w-auto" />
      <div className="container mx-auto text-center">
      </div>
    </section>
  );
};

export default Banner;
