import Header from "./header/header.js";
import Banner from "./banner/banner.js";
import Benefits from "./benefits/benefits.js";
import Mintdetails from "./mintdetails/mintdetails.js";
import Story from "./story/story.js";
import About from "./about/about.js";
import FAQ from "./faq/faq.js";
import Footer from "./footer/footer.js";

function App() {
  return (
    <div className="bg-brand-background">
      <Header/>
      <Banner/>
      <Story/>
      <About/>
      <Mintdetails/>   
      <Benefits/>
      <FAQ/>  
      <Footer/>
    </div>
  );
}

export default App;
