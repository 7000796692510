import React from "react";
import storybanner1 from "./MTLX_Banner1.jpg";
import storybanner2 from "./MTLX_Banner2.jpg";

const Story = () => {
  return (
    <div className="p-8 md:p-16 lg:p-24">
      <section className="bg-brand-background ">
        <div className="p-6 md:p-10 lg:p-12">
          <h1 className="lg:text-4xl text-white font-silkscreen text-center mb-5 ">
            Once upon a time ...
          </h1>
          <div className="md:w-2/3 lg:flex-initial m-auto mt-10">
            <div className="text-white text-center text-md font-silkscreen">
              <p>
                ... in a distant galaxy far away, there existed 33 angels who
                were on a mission to search for their original bodies, as well
                as the bodies of their brothers and sisters. The Metallic Angels
                were created from a special substance, which gave them immense
                powers and abilities. <br></br>
                <br></br>
                They were also bound to physical forms, which they had to find
                in order to fully realize their potential. The Metallic Angels
                journeyed through the vast expanse of space, encountering many
                challenges and obstacles along the way.
              </p>
            </div>
          </div>
          <div className="m-auto mt-20 mb-20">
            <img src={storybanner2} alt="banner1" className="max-w-full" />
          </div>

          <div className="md:w-2/3 md:flex-initial lg:flex-initial md:m-auto">
            <div className="text-white text-center font-silkscreen">
              <p>
                They never gave up on their quest, knowing that their ultimate
                goal was worth the sacrifice. Finally, after many years, the
                angels reached earth. They searched through the various corners
                of the planet and after much searching, they finally found what
                they were looking for.<br></br>
                <br></br>
                In total, they found 111 Metallic Ordinals - one skull for each
                of the angels - as well as their brothers and sisters skulls.
                With the Metallic Ordinals now reunited, the Angels were able to
                fully unleash their powers and abilities. <br></br>
              </p>
            </div>
          </div>

          <div className="md:w-3/4 md:relative m-auto mt-20 mb-20">
            <img
              src={storybanner1}
              alt="banner2"
              className="w-full h-full"
            />
          </div>

          <div className="md:w-2/3 lg:flex-initial m-auto mt-10">
            <div className="text-white text-center font-silkscreen">
              <p>
                The angels lived among the people, watching over them and
                helping them whenever they were in need. They used their
                newfound strength to protect the earth and its inhabitants by
                guiding them into the world of web3. <br></br>
                <br></br>
                Finally the Angels together with their Skulls became the
                guardians of the planet. And, as the centuries passed, the
                grateful people of earth came to know the Metallic Angels and
                the Metallic Ordinals for their protection and guidance.
                <br></br>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Story;
